.hero-section {
    width: 100%;
    height: 100vh; /* Full viewport height for larger screens */
    overflow: hidden;
    position: relative;
}

.hero-section .carousel-item img {
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    transform-origin: center center;
}

/* Common styles for all captions */
.hero-section .carousel-caption {
    max-width: 40%; /* Limit the width of the caption */
    position: absolute;
    left: 40px; /* Left-align the caption */
    bottom: 100px; /* Move the caption up */
    background-color: rgba(0, 0, 0, 0.5);
    padding: 20px;
    border-radius: 10px;
    text-align: left; /* Align the text to the left */
    color: #fff;
}

/* Ensure both slides have the same caption position */
.hero-section .carousel-item:nth-child(1) .carousel-caption,
.hero-section .carousel-item:nth-child(2) .carousel-caption {
    left: 40px; /* Align both captions to the left */
    bottom: 100px; /* Move both captions up */
    text-align: left; /* Align text to the left */
}

/* Responsive Design for Smaller Screens */
@media (max-width: 900px) {
    .hero-section {
        height: 60vh; /* Reduce height on smaller screens */
    }

    .hero-section .carousel-item img {
        height: 60vh;
    }

    .hero-section .carousel-caption {
        bottom: 40px; /* Adjust bottom position for smaller screens */
        padding: 10px; /* Reduce padding for smaller screens */
    }

    .hero-section .carousel-caption h3 {
        font-size: 1.5rem; /* Reduce heading size */
    }

    .hero-section .carousel-caption p {
        font-size: 1rem; /* Reduce paragraph size */
    }
}

/* Mobile Design (max-width: 576px) */
@media (max-width: 576px) {
    .hero-section .carousel-caption {
        max-width: 50%;

    }
    .hero-section {
        height: 50vh; /* Further reduce height for mobile */
    }

    .hero-section .carousel-item img {
        height: 50vh;
    }

    .hero-section .carousel-caption {
        bottom: 20px; /* Move captions closer to bottom */
        padding: 5px;
    }

    .hero-section .carousel-caption h3 {
        font-size: 1.2rem;
    }

    .hero-section .carousel-caption p {
        font-size: 0.9rem;
    }
}
