.footer-section {
    background-color: #333;
    color: #fff;
    padding: 60px 20px;
    text-align: left;
    font-family: "Kanit", sans-serif;
}

.footer-content {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.footer-column {
    flex: 1;
    margin: 20px;
    min-width: 200px;
}

.footer-title {
    font-size: 1.8rem;
    color: #d4af37;
    margin-bottom: 20px;
}

.company-info p {
    font-size: 1rem;
    color: #ccc;
}

.footer-links {
    list-style-type: none;
    padding: 0;
}

.footer-links li {
    margin-bottom: 10px;
}

.footer-links a {
    color: #fff;
    text-decoration: none;
    transition: color 0.3s ease;
}

.footer-links a:hover {
    color: #d4af37;
}

.social-media .social-icons {
    display: flex;
    gap: 20px;
}

.social-icon {
    font-size: 1.5rem;
    color: #000; /* Black color for icons */
    transition: color 0.3s ease, transform 0.3s ease;
}

.social-icon:hover {
    color: #d4af37; /* Gold color on hover */
    transform: scale(1.1); /* Slight scale effect on hover */
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .footer-content {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .footer-column {
        margin-bottom: 30px;
    }

    .social-media {
        margin-top: 20px;
    }
}
