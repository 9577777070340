.scrolling-text-wrapper {
    width: 100%;
    overflow: hidden;
    background-color: #d4af37;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1001; /* Ensure it's above the navbar */
    height: 40px;
    display: flex;
    align-items: center;
    transition: top 0.3s ease-in-out;
}

.scrolling-text-wrapper.hidden {
    top: -40px; /* Move the element up out of view */
}

.scrolling-text-wrapper.visible {
    top: 0; /* Ensure it stays at the top */
}

.scrolling-text {
    display: flex;
    animation: scrollLeft 20s linear infinite;
}

.scrolling-text-item {
    flex-shrink: 0;
    width: 100%;
    text-align: center;
    font-size: 1rem;
    font-weight: bold;
    color: #fff;
    padding: 10px 0;
}

@keyframes scrollLeft {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(-100%);
    }
}
