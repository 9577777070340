/* General styling for the Contact Us page */
.contact-us-page {
    max-width: 1200px;
    margin: 0 auto;
    padding: 40px 20px;
    background-color: #f4f4f4;
    font-family: 'Roboto', sans-serif;
    color: #333;
}

/* Banner Section */
.contact-banner {
    background: url('/public/images/banner.png') 150px center/cover no-repeat;
    height: 320px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    color: #fff;
    padding-left: 150px; 
}

.banner-overlay {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
}

.contact-banner h1 {
    font-size: 2.8rem;
    font-weight: 600;
    margin-bottom: 10px;
    color: #d4af37;
}

.contact-banner p {
    font-size: 1.2rem;
    font-weight: 600;
    color: black;
}

/* Contact Information Section */
.contact-info {
    text-align: center;
    margin: 50px 0;
}

.contact-info h2 {
    font-size: 2.2rem;
    font-weight: 600;
    color: #d4af37; /* Golden */
    margin-bottom: 30px;
}

/* Flexbox layout for Contact Info Grid */
.contact-info-grid {
    display: flex; /* Use flex to place items next to each other */
    gap: 20px; /* Space between the items */
    justify-content: center; /* Center them horizontally */
    margin-bottom: 40px;
}

.info-item {
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    width: 300px; /* Ensure both items have consistent width */
    text-align: center;
}

.info-item:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
}

.info-item h3 {
    font-size: 1.3rem;
    color: #d4af37; /* Golden font */
    margin-top: 10px;
}

.info-item p {
    font-size: 1rem;
    color: #666;
}

.info-icon {
    font-size: 2rem;
    color: #d4af37; /* Golden */
}

/* Map Section */
.map-section {
    text-align: center;
    margin: -40px 0; /* Pushed slightly up */
}

.map-section h2 {
    font-size: 2rem;
    font-weight: 600;
    color: #d4af37; /* Golden color for the heading */
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.map-icon {
    margin-right: 10px;
    font-size: 1.5rem;
    color: #d4af37;
}

.map-section .MapComponent {
    width: 100%;
    height: 80px; /* Reduced height of the map */
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* Contact Form Section */
.contact-form-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 50px 0;
    gap: 40px;
}

.contact-text {
    flex: 1;
    text-align: left;
}

.contact-text h2 {
    font-size: 2rem;
    color: #333;
    font-weight: 600;
    margin-bottom: 20px;
}

.contact-text p {
    font-size: 1.1rem;
    color: #666;
    line-height: 1.6;
    margin-bottom: 30px;
}

/* Contact Form */
.contact-form {
    flex: 1;
    background-color: #fff;
    padding: 60px 40px; /* Increased padding for a taller form */
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    max-width: 600px;
}

.contact-form h2 {
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 40px; /* Increased margin to make the form taller */
    color: #333;
}

.form-group {
    margin-bottom: 30px; /* Increased margin between form fields */
    text-align: left;
}

.form-group label {
    font-size: 1rem;
    margin-bottom: 8px;
    display: block;
    color: #333;
}

.form-group input,
.form-group textarea {
    width: 100%;
    padding: 14px; /* Increased padding inside input fields */
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 1.1rem; /* Slightly larger font size */
    font-family: inherit;
    color: #333;
    background-color: #fafafa;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.form-group input:focus,
.form-group textarea:focus {
    border-color: #d4af37;
    outline: none;
}

.form-group textarea {
    resize: none;
    height: 200px; /* Increased height of the text area */
}

/* Submit Button */
.submit-button {
    display: inline-block;
    padding: 15px 30px; /* Increased padding for a larger button */
    font-size: 1.3rem; /* Slightly larger font size */
    background-color: #d4af37;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 600;
    transition: background-color 0.3s ease;
}

.submit-button:hover {
    background-color: #b8860b;
}

/* Responsive Design */
@media (max-width: 768px) {
    /* Hide the banner on smaller screens */
    .contact-banner {
        display: none;
    }

    .contact-info-grid {
        flex-direction: column; /* Stack the boxes on smaller screens */
    }

    .info-item {
        width: 100%; /* Make items full width on smaller screens */
    }

    .contact-form-container {
        flex-direction: column;
        align-items: center;
    }

    .contact-form {
        max-width: 100%;
        padding: 50px 20px; /* Adjusted padding for smaller screens */
    }

    .contact-text {
        text-align: center;
    }
}
