/* General styling for the About Us page */
.about-us-page {
    max-width: 1200px;
    margin: 0 auto;
    padding: 40px 20px;
    background-color: #fff;
    font-family: 'Kanit', sans-serif;
    color: #333;
}

/* Section containers */
section {
    margin-bottom: 60px;
}

/* Company Overview Section */
.company-overview {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 40px;
    padding: 20px;
}

.company-overview-text {
    flex: 1;
}

.company-overview-text h1 {
    font-size: 2.5rem;
    font-weight: 700;
    color: #d4af37;
    margin-bottom: 20px;
}

.company-overview-text p {
    font-size: 1.2rem;
    line-height: 1.8;
    color: #666;
}

.company-overview-image {
    flex: 1;
}

.company-overview-image img {
    width: 100%;
    height: auto;
    border-radius: 10px;
}

/* Mission Section */
.mission-section {
    text-align: center;
    background-color: #f8f8f8;
    padding: 40px 20px;
    border-radius: 10px;
}

.mission-section h2 {
    font-size: 2.5rem;
    font-weight: 700;
    color: #d4af37;
    margin-bottom: 20px;
}

.mission-section p {
    font-size: 1.2rem;
    color: #666;
    line-height: 1.8;
}

/* Enquire Now Button under the Mission section */
.enquire-now-container {
    text-align: center;
    margin-top: 20px;
}

.enquire-now-button {
    padding: 15px 40px;
    font-size: 1.2rem;
    background-color: #d4af37;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 600;
    transition: background-color 0.3s ease;
}

.enquire-now-button:hover {
    background-color: #b8860b;
}

/* Why We’re Different Section */
.why-different {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 40px;
    padding: 20px;
}

.why-different-image { /* Image moved to the left */
    flex: 1;
}

.why-different-image img {
    width: 100%;
    height: auto;
    border-radius: 10px;
}

.why-different-text { /* Text moved to the right */
    flex: 1;
}

.why-different-text h2 {
    font-size: 2.5rem;
    font-weight: 700;
    color: #d4af37;
    margin-bottom: 20px;
}

.why-different-text p {
    font-size: 1.2rem;
    line-height: 1.8;
    color: #666;
}

/* Map Section */
.map-section {
    text-align: center;
    padding: 40px 20px;
}

.map-section h2 {
    font-size: 2.5rem;
    font-weight: 700;
    color: #d4af37;
    margin-bottom: 20px;
}

/* Responsive Design */
@media (max-width: 768px) {
    .company-overview, .why-different {
        flex-direction: column;
        text-align: center;
    }

    .company-overview-image img, .why-different-image img {
        max-width: 100%;
    }

    .company-overview-text, .why-different-text {
        text-align: center;
    }

    .mission-section h2, .why-different h2 {
        font-size: 2rem;
    }
}
