.how-it-works-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 20px;
    background-color: #f8f8f8;
    color: #333;
}

.how-it-works-title {
    font-size: 2.5rem;
    font-weight: 700;
    color: #d4af37;
    margin-bottom: 10px;
}

.how-it-works-content {
    display: flex;
    max-width: 1200px;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 30px;
    gap: 50px;
}

.how-it-works-text {
    flex: 1;
    max-width: 500px;
    text-align: left;
}

.how-it-works-text p {
    font-size: 1.1rem;
    line-height: 1.8;
    color: #666;
    margin-bottom: 15px;
}

/* Steps container */
.steps-container {
    flex: 1;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 550px;
    height: 550px;
}

.central-image {
    position: absolute;
    width: 250px;
    height: 250px;
    top: 100px;
    border-radius: 50%;
    overflow: hidden;
    z-index: 1;
}

.central-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}

/* Step items */
.step-item {
    position: absolute;
    width: 140px;
    height: 140px;
    text-align: center;
    font-weight: bold;
}

.step-item img {
    width: 90px;
    height: 90px;
    background-color: #b8b8b8;
    padding: 10px;
    border-radius: 50%;
    transition: transform 0.3s ease, background-color 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;
}

.step-item img:hover {
    background-color: #d4af37;
    transform: scale(1.05);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.step-item h3 {
    font-size: 1.2rem;
    margin-top: 5px;
    color: #333;
}

/* Positioning of steps (for larger screens) */
.top-left { top: 15%; left: 0; }
.top-right { top: 15%; right: 0; }
.bottom-left { bottom: 15%; left: 0; }
.bottom-right { bottom: 15%; right: 0; }
.bottom-center { bottom: 5%; left: 50%; transform: translateX(-50%); }

/* Responsive design for tablet and smaller screens (max-width: 768px) */
@media (max-width: 768px) {
    .how-it-works-content {
        flex-direction: column;
        padding: 20px;
        gap: 30px;
    }

    .steps-container {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center; /* Center steps */
        gap: 20px; /* Space between steps */
    }

    .step-item {
        position: relative;
        width: 100%; /* Full width for each step */
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center; /* Center content */
        margin: 15px 0; /* Space between steps */
        padding: 20px;
        background-color: #f1f1f1; /* Professional background */
        border-radius: 10px;
        text-align: center; /* Center text inside the step */
    }

    .step-item img {
        width: 50px;
        height: 50px;
        background-color: #d4af37; /* Gold color for images */
        margin-right: 20px; /* Space between image and text */
    }

    .step-item h3 {
        font-size: 1rem;
        color: #333;
    }

    .central-image {
        display: none; /* Hide central image on tablet and smaller screens */
    }
}

/* Mobile Design (max-width: 576px) */
@media (max-width: 576px) {
    .how-it-works-title {
        font-size: 1.8rem; /* Smaller title size */
    }

    .steps-container {
        gap: 15px; /* Slightly reduce the gap on mobile */
    }

    .step-item {
        padding: 15px; /* Adjust padding for smaller screens */
    }

    .step-item img {
        width: 40px; /* Smaller image size */
        height: 40px;
    }

    .step-item h3 {
        font-size: 0.9rem; /* Adjust font size for step titles */
    }
}
