/* Floating Icons Container */
.floating-icons {
    position: fixed;
    right: 20px; /* Keep the icons on the right side */
    bottom: 250px; /* Moved icons further up */
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1000;
}

/* Floating Icon Styles */
.floating-icons a {
    margin-bottom: 15px;
    transition: transform 0.3s ease;
}

.floating-icons a:hover {
    transform: scale(1.1);
}

.floating-icons img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Scroll to Top Button */
.scroll-top {
    display: none; /* Hidden initially */
    position: fixed;
    bottom: 20px; /* Keep the scroll-to-top button in a lower position */
    right: 20px; /* On the right side */
    z-index: 1000;
}

.scroll-top img {
    width: 50px;
    height: 50px;
    border-radius: 50%; /* No border */
    box-shadow: none; /* Removed box-shadow/border */
}
