.about-us-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 60px 20px;
    background-color: #f8f8f8; /* Light grey background */
    color: #333;
    text-align: center;
}

.about-title {
    font-size: 2.8rem;
    font-weight: 700;
    color: #d4af37; /* Gold color for the title */
    margin-bottom: 40px; /* Space between title and content */
}

.about-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1200px;
    width: 100%;
    gap: 50px; /* Space between text and image */
    padding: 20px;
    background-color: #fff; /* White background for content */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    border-radius: 10px; /* Rounded corners for professional look */
    flex-wrap: wrap; /* Allow wrapping on smaller screens */
}

.about-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 600px;
    text-align: left;
}

.about-text h3 {
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 20px;
    color: #d4af37; /* Gold color for subheading */
}

.about-text h3 span {
    color: #b22222; /* Dark Red for highlighted text */
}

.about-text p {
    font-size: 1.1rem;
    line-height: 1.8;
    margin-bottom: 15px;
    color: #666; /* Darker grey for text */
}

.center-image {
    flex-shrink: 0;
    width: 400px; /* Adjust the width of the image */
    display: flex;
    justify-content: center;
    align-items: center;
}

.center-image img {
    width: 100%;
    height: auto;
    object-fit: cover; /* Ensure image fits without distortion */
    border-radius: 10px; /* Slight border-radius for image */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Image shadow for depth */
}

.more-button-container {
    margin-top: 20px;
}

.about-us-button {
    padding: 12px 30px;
    background-color: #d4af37; /* Gold background */
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.about-us-button:hover {
    background-color: #b8860b; /* Darker gold on hover */
}

/* For responsive design */
@media (max-width: 768px) {
    .about-content {
        flex-direction: column;
        align-items: center;
    }

    .center-image {
        margin-top: 20px;
        width: 100%;
        max-width: 300px; /* Image size reduces on smaller screens */
    }

    .about-text {
        text-align: center;
        max-width: 100%;
        padding-right: 0;
    }

    .about-title {
        font-size: 2.5rem;
    }
}
