.why-choose-section {
    padding: 60px 20px;
    background-color: #f8f8f8;
    text-align: center;
}

.why-choose-title-container {
    margin-bottom: 40px; /* Space between title and content box */
}

.why-choose-headline {
    font-size: 2.8rem;
    font-weight: 700;
    color: #d4af37; /* Gold color for title */
    margin: 0 auto;
}

.why-choose-content {
    max-width: 1100px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    border-radius: 10px;
    margin: 0 auto;
    padding: 40px;
}

.why-choose-steps {
    display: flex;
    flex-direction: column;
    gap: 40px; /* Space between rows */
}

.why-choose-row {
    display: flex;
    align-items: center;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    border-radius: 10px;
    padding: 20px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.why-choose-row:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.why-choose-row.shifted {
    margin-left: 40px; /* Shifted for staggered layout */
}

.why-choose-image {
    flex: 1;
    max-width: 35%;
    padding-right: 20px;
}

.why-choose-image img {
    width: 100%;
    height: 100px;
    object-fit: contain;
    border-radius: 8px;
}

.why-choose-text {
    flex: 2;
    text-align: left;
}

.why-choose-text h3 {
    font-size: 1.8rem;
    color: #333;
    margin-bottom: 10px;
    font-weight: 600;
}

.why-choose-text p {
    font-size: 1.1rem;
    color: #666;
    line-height: 1.8;
}

.enquiry-button-container {
    margin-top: 40px;
}

.enquiry-button {
    padding: 12px 30px;
    background-color: #d4af37; /* Gold background */
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.enquiry-button:hover {
    background-color: #b8860b; /* Darker gold on hover */
}

/* Responsive design */
@media (max-width: 768px) {
    .why-choose-row {
        flex-direction: column;
        text-align: center;
    }

    .why-choose-image {
        max-width: 80%;
        padding-right: 0;
    }

    .why-choose-row.shifted {
        margin-left: 0;
    }
}
