/* Default Navbar Styles */
.navbar {
    background-color: #fff;
    padding: 3px 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    position: relative;
    z-index: 1000;
    margin-top: 40px;
    font-family: "Kanit", system-ui;
    font-weight: 400;
    font-style: normal;
}

.container-fluid {
    max-width: 1200px;
    margin: 0 auto;
}

.navbar-brand img {
    height: 50px;
    margin-right: 20px;
}

.center-section {
    flex-grow: 1;
}

.navbar-nav {
    list-style: none;
    margin: 0;
    padding: 0;
}

.navbar-nav .nav-item {
    margin-left: 20px;
}

.navbar-nav .nav-link {
    text-decoration: none;
    color: #333;
    font-size: 1.2rem;
    font-weight: 600;
    padding: 5px 10px;
    transition: color 0.3s ease;
}

.navbar-nav .nav-link:hover {
    color: #d4af37;
}

.right-section .follow-us {
    display: flex;
    align-items: center;
    justify-content: center;
}

.right-section .call-us-button {
    display: inline-block;
    padding: 8px 15px;
    background-color: #d4af37;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    margin-top: 10px;
    font-size: 1.2rem;
    font-weight: 600;
}

.call-us-button i {
    margin-left: 5px;
}

.call-us-button:hover {
    background-color: #b8860b;
}

.follow-us a {
    color: #333;
    margin-left: 10px;
    font-size: 1.4rem;
    transition: color 0.3s ease;
}

.follow-us a:hover {
    color: #d4af37;
}

/* Hamburger Menu (visible on small screens) */
.hamburger-menu {
    display: none;
    cursor: pointer;
}

@media (max-width: 900px) {
    /* Show hamburger menu icon on tablets and smaller screens */
    .hamburger-menu {
        display: block;
        font-size: 1.8rem;
        color: #333;
        margin-left: auto;
    }

    /* Center the menu when open */
    .center-section {
        display: none;
    }

    .center-section.open {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: absolute;
        top: 70px;
        left: 0;
        right: 0;
        background-color: #fff;
        z-index: 999;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        padding: 20px;
    }

    .navbar-nav {
        flex-direction: column;
        text-align: center;
    }

    .navbar-nav .nav-item {
        margin: 10px 0;
    }

    .right-section {
        display: none;
    }
}
