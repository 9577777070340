/* General styling for the Services Page */
.services-page {
    max-width: 1200px;
    margin: 0 auto;
    padding: 40px 20px;
    background-color: #f4f4f4;
    font-family: 'Roboto', sans-serif;
    color: #333;
}

/* Banner Section */
.services-banner {
    background: url('/public/images/banner-2.png') 10px center/cover no-repeat;
    height: 320px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    color: #F2F3F2;
}

.banner-overlay1 {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-left: 50px;
}

.services-banner h1 {
    font-size: 2.8rem;
    font-weight: 600;
    margin-bottom: 10px;
    color: #d4af37;
    text-align: left;
}

.services-banner p {
    font-size: 1.2rem;
    font-weight: 600;
    color: black;
    text-align: left;
}

/* Our Services Section */
.our-services {
    margin: 60px 0;
    text-align: center;
    background-color: #f9f9f9;
    padding: 40px 20px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.our-services h2 {
    font-size: 2.5rem;
    color: #d4af37;
    margin-bottom: 40px;
}

.services-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
}

.service-item {
    background-color: #fff;
    padding: 30px;
    border-radius: 10px;
    flex-basis: calc(50% - 20px);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    text-align: left;
}

.service-item:hover {
    transform: translateY(-10px);
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
}

.service-item h3 {
    font-size: 1.8rem;
    color: #333;
    margin-bottom: 15px;
}

.service-item p {
    font-size: 1.1rem;
    color: #555;
}

/* Our Process Section */
.our-process-section {
    margin: 60px 0;
    padding: 60px 40px;
    text-align: center;
    background-color: #fafafa;
    border-radius: 12px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

.our-process-section h2 {
    font-size: 2.8rem;
    color: #d4af37;
    margin-bottom: 40px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1px;
    text-align: center;
}

/* Process Steps Container */
.process-steps-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Two columns */
    grid-gap: 30px; /* Slightly reduced gap between items */
    max-width: 1400px;
    margin: 0 auto;
    padding: 0 40px;
}

/* Process Step Item */
.process-step-item {
    background-color: #fff;
    padding: 5px 15px; /* Minimal padding */
    border-radius: 12px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    text-align: center; /* Center-align text horizontally */
    position: relative;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    min-height: 100px; /* Reduced min-height */
    width: 100%;
    word-wrap: break-word;
    display: flex; /* Flexbox for vertical centering */
    flex-direction: column; /* Stack content vertically */
    justify-content: center; /* Center content vertically */
    align-items: center; /* Center content horizontally */
}

.process-step-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
}

.process-step-item h3 {
    font-size: 1.4rem; /* Keeping original font size */
    color: #333;
    margin-bottom: 4px; /* Reduced margin */
    font-weight: 600;
    line-height: 1.1; /* Tightened line height */
}

.process-step-item span {
    font-size: 2rem; /* Keeping original font size for numbers */
    font-weight: 700;
    color: #d4af37;
    position: absolute;
    top: -20px;
    left: -20px;
    background: #fff;
    border: 2px solid #d4af37;
    border-radius: 50%;
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.process-step-item p {
    font-size: 1rem; /* Keeping original font size for paragraphs */
    color: #666;
    line-height: 1.3; /* Tightened line height for paragraphs */
    word-wrap: break-word;
    margin: 0; /* Removed paragraph margins */
}

/* Responsive Design */
@media (max-width: 768px) {
    .process-steps-container {
        grid-template-columns: 1fr; /* Switch to single column for mobile */
        padding: 0 20px;
    }

    .process-step-item h3 {
        font-size: 1.4rem; /* No change in font size */
    }

    .process-step-item p {
        font-size: 1rem; /* No change in font size */
    }
}

/* CTA Section */
.cta-section {
    background-color: #d4af37;
    padding: 60px 20px;
    text-align: center;
    color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.cta-section h2 {
    font-size: 2.2rem;
    margin-bottom: 20px;
}

.cta-button {
    background: linear-gradient(135deg, #fff, #d4af37);
    color: #d4af37;
    font-size: 1.2rem;
    padding: 15px 30px;
    border: none;
    border-radius: 30px;
    cursor: pointer;
    font-weight: 600;
    transition: all 0.3s ease;
}

.cta-button:hover {
    background: linear-gradient(135deg, #d4af37, #fff);
    color: #fff;
}

/* Responsive Design */
@media (max-width: 900px) {
    /* Hide the banner on smaller screens */
    .services-banner {
        display: none;
    }

    .process-steps-container {
        grid-template-columns: 1fr; /* Switch to single column for mobile */
        padding: 0 20px;
    }

    .process-step-item h3 {
        font-size: 1.4rem; /* No change in font size */
    }

    .process-step-item p {
        font-size: 1rem; /* No change in font size */
    }
}

/* Responsive Design for Services Grid */
@media (max-width: 768px) {
    .services-grid, .steps {
        flex-direction: column;
        gap: 20px;
    }

    .service-item, .step-item {
        flex-basis: 100%;
    }

    .our-services h2, .how-it-works h2 {
        font-size: 2rem;
    }

    .service-item h3, .step-item h3 {
        font-size: 1.5rem;
    }
}