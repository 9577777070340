.faq-section {
    background-color: #f8f8f8; /* Light grey background for the entire section */
    padding: 20px 20px;
    text-align: center;
    width: 100%;
}

.faq-title-container {
    margin-bottom: 40px;
}

.faq-title {
    font-size: 2.8rem;
    font-weight: 700;
    color: #d4af37; /* Gold color for title */
    margin-bottom: 0;
}

.faq-content {
    max-width: 1000px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    border-radius: 10px;
    margin: 0 auto;
    padding: 40px;
    text-align: left;
    margin-bottom: 40px; /* Add space below the content box */
}

.faq-container {
    margin-top: 20px;
}

.faq-item {
    background-color: #fff; /* All FAQ items have white background */
    border-radius: 8px;
    margin-bottom: 20px;
    padding: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Box shadow for depth */
    transition: all 0.3s ease;
}

.faq-question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    font-size: 1.3rem;
    font-weight: 600;
    color: #d4af37; /* Gold color for question text */
}

.faq-icon {
    font-size: 1.5rem;
    color: #d4af37; /* Gold color for the '+' or '-' icon */
    transition: transform 0.3s ease;
}

.faq-answer {
    margin-top: 10px;
    padding-left: 10px;
    border-left: 3px solid #d4af37; /* Golden border for answer section */
}

.faq-answer p {
    font-size: 1rem;
    color: #666;
    line-height: 1.6;
}

/* Hover and transition effects for FAQ items */
.faq-item:hover {
    transform: scale(1.02); /* Slight hover effect for better user engagement */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15); /* Deeper shadow on hover */
}

/* Responsive Design */
@media (max-width: 768px) {
    /* Hide the banner on smaller screens */
    .faq-banner {
        display: none;
    }
}
