/* General styles for the FAQ page */
.faq-page-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 40px 20px;
    background-color: #f4f4f4;
    font-family: 'Roboto', sans-serif;
    color: #333;
}

/* Banner Section */
.faq-page-banner {
    background: url('/public/images/banner.png') 150px center/cover no-repeat;
    height: 320px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    color: #fff;
}

.faq-page-banner-overlay {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.faq-page-banner h1 {
    font-size: 3rem;
    font-weight: 600;
    color: #d4af37;
}

/* Our Mission Section */
.faq-page-mission {
    text-align: center;
    padding: 50px 20px;
    background-color: #fff;
    border-radius: 10px;
    margin: 40px 0;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.faq-page-mission h2 {
    font-size: 2.5rem;
    color: #d4af37;
    margin-bottom: 20px;
}

.faq-page-mission p {
    font-size: 1.2rem;
    color: #666;
    line-height: 1.6;
    margin-bottom: 30px;
}

.faq-page-enquire-button {
    background-color: #d4af37;
    color: #fff;
    padding: 15px 30px;
    font-size: 1.2rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.faq-page-enquire-button:hover {
    background-color: #b8860b;
}

/* Map Section */
.faq-page-map-section {
    text-align: center;
    margin: 60px 0;
}

.faq-page-map-section h2 {
    font-size: 2rem;
    font-weight: 600;
    color: #d4af37; /* Golden color for the heading */
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.faq-page-map-icon {
    margin-right: 10px;
    font-size: 1.5rem;
    color: #d4af37;
}

.faq-page-map-section .MapComponent {
    width: 100%;
    height: 200px; /* Adjust the height as needed */
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}
