/* General styles for the Blog page */
.blog-page-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 40px 20px;
    background-color: #f4f4f4;
    font-family: 'Roboto', sans-serif;
    color: #333;
}

/* Banner Section */
.blog-page-banner {
    background: url('/public/images/banner.png') 150px center/cover no-repeat;
    height: 320px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    color: #fff;
}

.blog-page-banner-overlay {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.blog-page-banner h1 {
    font-size: 3rem;
    font-weight: 600;
    color: #d4af37;
    transform: translateX(-150px);
    padding-bottom: 40px;
}

/* Blog Content Section */
.blog-content {
    padding: 60px 20px;
    background-color: #fff;
    border-radius: 10px;
    margin: 40px 0;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.blog-image {
    text-align: center;
    margin-bottom: 40px;
}

.blog-image img {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
}

/* Headings */
.blog-content h2 {
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 20px;
}

.blog-content h3 {
    font-size: 1.8rem;
    color: #d4af37;
    margin-top: 40px;
}

.blog-content h4 {
    font-size: 1.4rem;
    color: #666;
    margin-top: 20px;
}

/* Text Content */
.blog-content p {
    font-size: 1.1rem;
    color: #666;
    line-height: 1.6;
    margin-bottom: 20px;
}

.blog-content ul {
    list-style-type: none;
    padding-left: 0;
}

.blog-content ul li {
    margin-bottom: 10px;
}

.blog-content ul li strong {
    font-weight: 600;
}

/* Map Section */
.blog-page-map-section {
    text-align: center;
    margin: 60px 0;
}

.blog-page-map-section h2 {
    font-size: 2rem;
    font-weight: 600;
    color: #d4af37; /* Golden color for the heading */
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.blog-page-map-icon {
    margin-right: 10px;
    font-size: 1.5rem;
    color: #d4af37;
}

.blog-page-map-section .MapComponent {
    width: 100%;
    height: 200px; /* Adjust the height as needed */
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* Responsive Design */
@media (max-width: 768px) {
    /* Hide the banner on smaller screens */
    .blog-page-banner {
        display: none;
    }
}
